import { GatsbySeo } from 'gatsby-plugin-next-seo'

import EcoDesignMontanhaComponent from '../../components/Campanha/EcoDesignMontanhaComponent'

function EcodesignMontanha() {
  return (
    <>
      <GatsbySeo title="Campanha | Decathlon" noindex nofollow />
      <EcoDesignMontanhaComponent />
    </>
  )
}

export default EcodesignMontanha
